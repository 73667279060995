<template>
  
        <div class="nameBox" :class="money||remainNum ?'tallHeight':''">
            <div>{{meetName}}</div>
            <div class="time">
                {{meetDate}}
                <!-- <span v-if='rebackFeeStatus==3'>退费中</span> -->
                <span v-if='rebackFeeStatus==2'>退费成功</span>
            </div>
            <div v-if='money||remainNum' class="signup">
                <div  class="money">
                    <span>报名费：</span>
                    <span class="red">{{money}}元</span>
                </div>
                <div  class="money num">
                    <span>剩余名额：</span>
                    <span >{{remainNum}}</span>
                </div>
            </div>
        </div>
    
</template>

<script>
    export default {
        name:'meetTitle',
        props:{
            meetName:{
                type:String,
                default:''
            },
            meetDate:{
                type:String,
                default:''
            },
            money:{
                type:String,
                default:''
            },
            remainNum:{
                type:String,
                default:''
            },
            rebackFeeStatus:{
                type:String|Number,
                default:0
            }
        }
    }
</script>

<style lang="less" scoped>
.nameBox{    
    height: 1.6rem;
    background: url('../../../assets/detailBg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding:0.4rem 0.25rem 0;   
    box-sizing: border-box  ;
    div{
        font-size: 14px;
        font-weight: bold;
        color: #fff;
    }
    .time{
        font-weight: normal;
        color: #dfdfdf;
        margin-top: 0.15rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        span{
            color:#F62F2F;
        }
    }
    
    .signup{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-top: 0.15rem;
        
        .money{        
            color: #dfdfdf;      
            font-weight: normal;             
            .red{
                color:#f62f2f;
                font-weight: bold;
            }
        }
        .num{
            margin-left: 0.25rem;
        }
    }
}
.tallHeight{
    height:2.0rem;
}

</style>