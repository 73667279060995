<template>
    <div>
        <meet-title :meetName="infos.meetTitle" :meetDate="meetDate" />
        <div class="infoBox">
            <div class="address">
                <span class="addressTitle">培训地址：</span>
                <span>{{infos.meetAddressName||''}}{{infos.meetDetailedAddress}}</span>
            </div>
            <div class='mapBox' id="mapBox" >

            </div>
        </div>
    </div>
</template>

<script>
import $ from "jquery";
import MeetTitle from "./component/meetTitle.vue";
    export default {
        name:'meetTraffic',
        components:{
            MeetTitle
        },
        data(){
            return{

               infos:{},
            }
        },
        computed:{
            meetDate(){
                let str=this.infos.meetTimeStart.split('-').join('/')+"-"+this.infos.meetTimeEnd.split('-').join('/')
                return str;
            }
        },
        created(){
            let infos=this.$route.params;
            this.infos=infos;
        },
        mounted(){

            this.getMapCenter();
        },
        methods:{
            getMapCenter(){
                let address=this.infos.meetAddressName+this.infos.meetDetailedAddress;
                let url=encodeURI(`https://apis.map.qq.com/ws/geocoder/v1?address=${address}&key=RWXBZ-KC664-GX5U4-X7VJM-RM5N7-VBFQX&output=jsonp`)
               $.get(
                    url,
                    res=>{
                        if(res&&res.status==0){
                            this.initMap(res.result.location.lat,res.result.location.lng);
                        }
                    },'jsonp'

                )
            },
            initMap(lat,lng){
                var center = new qq.maps.LatLng(lat, lng)
                var map = new qq.maps.Map(document.getElementById('mapBox'), {
                center: center,
                zoom: 17
                })
                var marker = new qq.maps.Marker({
                    position: center,
                    map: map
                    })

            }
        },
    }
</script>

<style lang="less" scoped>
.infoBox{
    margin-top: -0.2rem;
    background: #fff;
    border-radius: 20px 20px 0px 0px;
    padding: 0.35rem 0.25rem 0;
    .address{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        font-size: 14px;
        color: #333;;
        .addressTitle{
            color: #999;
            flex:0 0 72px;
        }
    }
    .mapBox{
        margin-top: 0.35rem;
        width: 3.25rem;
        height: 3.25rem;
    }
}
</style>
